<template>
  <div class="pb-5 ml-noi-quy-hoi-bai">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="mb-3"><span>Nội quy</span> / <span>Nội quy hỏi bài</span></div>
        </div>
      </div>
    </div>
    <div class="container container-mobile">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="noi-quy">
            <div class="page-title font-weight-bold text-center">Nội quy hỏi bài</div>
            <div class="block-content">
              <div class="title">NỘI QUY CHUNG:</div>
              <div class="info">Sử dụng tiếng Việt có dấu khi đặt câu hỏi, không sử dụng từ lóng, teencode.</div>
              <div class="info">Không đặt tên tài khoản, avatar gây hiểu nhầm là thành viên ban quản trị hoidap.mobiedu.vn hoặc có chứa từ ngữ nhạy cảm.</div>
              <div class="info">Không chia sẻ thông tin cá nhân tránh bị làm phiền hoặc làm phiền người khác.</div>
              <div class="info">Tôn trọng thành viên khác, không sử dụng từ ngữ thô tục, phản cảm, xúc phạm thành viên, luôn tuân thủ quy định luật pháp Việt Nam trên môi trường mạng.</div>
              <div class="info">Nội quy có thể được thay đổi mà không cần phải báo trước.</div>
            </div>
            <div class="block-content">
              <div class="title">NỘI QUY ĐẶT CÂU HỎI:</div>
              <div class="info">Mỗi lần đặt 1 câu hỏi hoặc 1 bài tập, không gửi hình ảnh dạng bộ đề, nhiều câu hỏi.</div>
              <div class="info">Một câu hỏi chỉ gửi 1 lần bằng 1 tài khoản, không được gửi trùng nội dung câu hỏi.</div>
              <div class="info">Câu hỏi phải liên quan đến nội dung học tập, phân loại đúng lớp, đúng môn.</div>
              <div class="info">Câu hỏi có nội dung, hình ảnh không rõ ràng hoặc vi phạm các lỗi quy định sẽ bị xử lý Báo vi phạm.</div>
            </div>
            <div class="block-content">
              <div class="title">NỘI QUY CÂU TRẢ LỜI:</div>
              <div class="info">Câu trả lời phải có giải thích đầy đủ, chi tiết từng bước giải.</div>
              <div class="info">Không tạo nhiều tài khoản để tăng điểm gian lận, vi phạm sẽ bị khóa tài khoản từ 7 ngày đến vĩnh viễn.</div>
              <div class="info">Không sao chép mạng vì người hỏi cần câu trả lời của riêng bạn.</div>
              <div class="info">Không spam, trả lời linh tinh để tăng điểm, vi phạm sẽ bị khoá tài khoản.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'mlearn'
}
</script>

<style lang="scss" scoped>
  .ml-noi-quy-hoi-bai{
    @media (max-width:576px) {
      background: var(--white);
    }
    .noi-quy{
      background: var(--white);
      box-shadow: 0px 2px 4px 0px #AEAEAE57;
      border-radius: 4px;
      padding:24px 40px;
      border: 1px solid #004390;
      @media (max-width:576px) {
        padding: 16px;
        box-shadow: none;
      }
      @media (max-width:1366px) {
        padding: 18px;
      }
      .page-title{
        font-size: 24px;
        @media (max-width:576px) {
          font-size: 20px;
        }
        @media (max-width:1366px) {
          font-size: 20px;
        }
      }
      .block-content{
        counter-reset: myCounter;
        padding-top:25px;
        @media (max-width:576px) {
          padding-top:20px;
        }
        @media (max-width:1366px) {
          padding-top:20px;
        }
        .title{
          text-align: left;
          text-transform: uppercase;
          font-weight: 700;
          position: relative;
          font-size: 18px;
          padding-left:17px;
          margin-bottom: 7px;
          @media (max-width:576px) {
            font-size: 15px;
            padding-left:10px;
            margin-bottom: 4px;
          }
          @media (max-width:1366px) {
            font-size: 15px;
            padding-left:10px;
            margin-bottom: 4px;
          }
          &::before{
            content: '';
            position: absolute;
            left: 0;
            background: #004390;
            top: 50%;
            transform: translateY(-50%);
            width: 6px;
            height: 28px;
            @media (max-width:576px) {
              width: 3px;
              height: 20px;
            }
            @media (max-width:1366px) {
              width: 3px;
              height: 20px;
            }
          }
        }
        .info{
          word-break: break-word !important;
          word-wrap: break-word !important;
          color: #65676B;
          font-size: 16px;
          padding-left:16px;
          @media (max-width:576px) {
            font-size: 14px;
            padding-left:10px;
          }
          @media (max-width:1366px) {
            font-size: 14px;
            padding-left:10px;
          }
          &::before{
            counter-increment: myCounter;
            content: counter(myCounter) ". ";
          }
        }
      }
    }
  }
</style>
