<template>
  <div class="container mb-5 pt-2">
    <div class="ml-khong-ton-tai text-center">
      <img src="~/mlearn/icons/not-found.svg" alt="Not found" class="not-found">
      <div class="text pt-3">Không tìm thấy trang này</div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'mlearn'
}
</script>

<style lang="scss" scoped>
  .ml-khong-ton-tai{
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
    border-radius: 8px;
    padding:130px 0;
    .text{
      font-weight: 500;
      font-size: 13px;
      color: #65676B;
    }
  }
</style>
