<template lang="html">
  <div class="content">
    <div class="maintenance">
      <h1> Website đang bảo trì, vui lòng đợi trong giây lát. </h1>
    </div>
  </div>
</template>
<script>
export default {
  layout: 'event'
}
</script>
<style lang="css" scoped>
body, html {
  height: 100%;
  width: 100%;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1em;
}
.maintenance h1 {
  font-size: 190%;
  color: var(--white);
  text-shadow: -2px 2px 3px rgba(0,0,0,0.5);
  color: #d6336c;
  text-align: center;
}
.maintenance {
  height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  fill: #d6336c;
}
.content {
  height: 100%;
  width: 100%;
  background-color: #5471B9;
}
</style>
