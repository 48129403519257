<template>
  <div class="container container-mobile mb-5 ml-huong-dan-su-dung">
    <div class="content">
      <div class="support-title">HƯỚNG DẪN THAM GIA CỘNG ĐỒNG HOIDAP.MOBIEDU.VN</div>
      <b-tabs content-class="mt-3 min-height" v-model="activeIndex">
        <b-tab>
          <template #title>
            <span class="d-none d-lg-block">Hướng dẫn đăng ký thành viên</span>
            <span class="d-lg-none">Đăng ký</span>
          </template>
<!--          <div v-html="contentTab1"></div>-->
          <b-card-text>
            <div class="text-justify text-break font-weight-bold pt-3 pb-2">
              Cách 1: Đăng ký qua tài khoản hoidap.mobiedu.vn
            </div>
            <div class="text-justify text-break mb-3">
              <div class="mb-1">Bước 1: Người dùng truy cập vào https://hoidap.mobiedu.vn/</div>
              Bước 2: Click vào “Đăng ký” trên thanh menu bên phải màn hình
            </div>
            <div class="text-justify text-break div-img">
              <img class="img" src="~/mlearn/images/login-buoc2.png" alt="">
            </div>
            <div class="text-justify text-break mt-3 mb-3">
              Bước 3: Màn hình hiện thị trang đăng ký, người dùng điền tất cả các thông tin theo các trường yêu cầu
            </div>
            <div class="text-justify text-break div-img">
              <img class="img-1" src="~/mlearn/images/login-b3.png" alt="">
            </div>
            <div class="text-justify text-break mb-3 mt-3">
              <div class="mb-1">Bước 4: Click vào “Đăng ký” để hoàn thành đăng ký tài khoản</div>
              Bước 5: Màn hình hiện thị về giao diện trang chủ hoidap.mobiedu.vn
            </div>
            <div class="text-justify text-break div-img">
              <img class="img" src="~/mlearn/images/login-b4.png" alt="">
            </div>
          </b-card-text>
          <b-card-text>
            <div class="text-justify text-break font-weight-bold pt-3 pb-2">
              Cách 2: Đăng ký tài khoản qua kênh Facebook
            </div>
            <div class="text-justify text-break mb-3">
              <div class="mb-1">Bước 1: Người dùng truy cập vào https://hoidap.mobiedu.vn/</div>
              Bước 2: Click vào “Đăng ký” trên thanh menu bên phải màn hình
            </div>
            <div class="text-justify text-break div-img">
              <img class="img" src="~/mlearn/images/login-buoc2.png" alt="">
            </div>
            <div class="text-justify text-break mt-3 mb-3">
              Bước 3: Màn hình hiện thị giao diện đăng nhập trên facebook như hình dưới
            </div>
            <div class="text-justify text-break div-img">
              <img class="img-1" src="~/mlearn/images/fb-login.png" alt="">
            </div>
            <div class="text-justify text-break mt-2">
              Bước 4: Sau khi điền thông tin click “Login” màn hình chuyển về giao diện trang chủ của hoidap.mobiedu.vn<br>
            </div>
          </b-card-text>
          <b-card-text>
            <div class="text-justify text-break font-weight-bold pt-3 pb-2">
              Cách 3: Đăng ký tài khoản qua kênh Google
            </div>
            <div class="text-justify text-break mb-3">
              <div class="mb-1">Bước 1: Người dùng truy cập vào https://hoidap.mobiedu.vn/</div>
              Bước 2: Click vào “Đăng ký” trên thanh menu bên phải màn hình
            </div>
            <div class="text-justify text-break div-img">
              <img class="img" src="~/mlearn/images/login-buoc2.png" alt="">
            </div>
            <div class="text-justify text-break mb-3 mt-3">
              Bước 3: Màn hình hiện thị giao diện đăng nhập trên Google như hình dưới
            </div>
            <div class="text-justify text-break div-img">
              <img class="img-gg" src="~/mlearn/images/login-gg.png" alt="">
            </div>
            <div class="text-justify text-break">
              Bước 4: Khách hàng điền email, mật khẩu và tiến hành đăng nhập thành công. Màn hình hiện thị về giao diện trang chủ hoidap.mobiedu.vn
            </div>
          </b-card-text>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="d-none d-lg-block">Hướng dẫn hỏi đáp trên hoidap.mobiedu.vn</span>
            <span class="d-lg-none">Hỏi đáp</span>
          </template>
          <b-card-text>
            <div class="text-justify text-break font-weight-bold pt-3 pb-2">
              1.	Hướng dẫn gửi câu hỏi
            </div>
            <div class="text-justify text-break mb-3">
              <div class="mb-1">Bước 1: Đăng nhập thành công vào hệ thống hoidap.mobiedu.vn</div>
              Bước 2: Click vào button “Đặt câu hỏi”
            </div>
            <div class="text-justify text-break div-img">
              <img class="img" src="~/mlearn/images/dat-cau-hoi.png" alt="">
            </div>
            <div class="text-justify text-break mb-3 mt-3">
              Bước 3: Hiện thị màn hiện thị popup để nhập nội dung
            </div>
            <div class="text-justify text-break div-img">
              <img class="img-gg" src="~/mlearn/images/dat-cau-hoi-popup.png" alt="">
            </div>
            <div class="text-justify text-break mt-3">
              Bước 4: Chọn “GỬI”. Màn hình hiện thị thông báo gửi câu hỏi thành công<br>
            </div>
          </b-card-text>
          <b-card-text>
            <div class="text-justify text-break font-weight-bold pt-3 pb-2">
              2.	Hướng dẫn trả lời câu hỏi
            </div>
            <div class="text-justify text-break mb-3">
              <div class="mb-1">Bước 1: Đăng nhập thành công vào hệ thống hoidap.mobiedu.vn</div>
              Bước 2: Chọn câu hỏi để trả lời, click “Trả lời”
            </div>
            <div class="text-justify text-break div-img">
              <img class="img-rep" src="~/mlearn/images/tra-loi.png" alt="">
            </div>
            <div class="text-justify text-break mt-3 mb-3">
              Bước 3: Màn hình hiện thị phần nhập câu trả lời như hình dưới, nhập đủ nội dung, đáp án và giải thích các bước giải. Sau đó ấn “Gửi trả lời”
            </div>
            <div class="text-justify text-break div-img">
              <img class="img-1" src="~/mlearn/images/tra-loi-slug.png" alt="">
            </div>
            <div class="text-justify text-break mt-3 mb-3">
              <div class="mb-1">Bước 4: Hệ thống báo gửi câu hỏi thành công</div>
              Bước 5: Màn hình hiện thị câu trả lời của người dùng phía dưới câu hỏi. Người trả lời sẽ nhận được điểm, lời cảm ơn, đánh giá từ mọi người
            </div>
            <div class="text-justify text-break div-img">
              <img class="img-register-gg" src="~/mlearn/images/view-tra-loi.png" alt="">
            </div>
          </b-card-text>
<!--          <div v-html="contentTab2"></div>-->
        </b-tab>
<!--        <b-tab>-->
<!--          <template #title>-->
<!--            <span class="d-none d-lg-block">Hướng dẫn học Gia sư</span>-->
<!--            <span class="d-lg-none">Học Gia sư</span>-->
<!--          </template>-->
<!--          <div v-html="contentTab3"></div>-->
<!--        </b-tab>-->
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'
const supportPaymentResource = new Resource('price/support-payment')

export default {
  layout: 'mlearn',
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    const activeIndex = parseInt(query.i) || 0
    const { data } = await supportPaymentResource.list()
    return {
      support: data,
      activeIndex
    }
  },
  computed: {
    contentTab1 () {
      if (!this.support.length) {
        return 'Chưa có nội dung'
      }
      const data = this.support.find(item => item.type === 'HUONGDANTHANHTOAN')
      return data?.content || 'Chưa có nội dung'
    },
    contentTab2 () {
      if (!this.support.length) {
        return 'Chưa có nội dung'
      }
      const data = this.support.find(item => item.type === 'HUONGDANSUDUNGGOISUB')
      return data?.content || 'Chưa có nội dung'
    },
    contentTab3 () {
      if (!this.support.length) {
        return 'Chưa có nội dung'
      }
      const data = this.support.find(item => item.type === 'HUONGDANHOCGIASU')
      return data?.content || 'Chưa có nội dung'
    }
  }
}
</script>
<style lang="scss">
.ml-huong-dan-su-dung{
  .content{
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
    border-radius: 8px;
    padding:24px;
    margin-top:20px;
    @media (max-width:576px) {
      box-shadow: none;
      border-radius: 0;
      padding:16px;
      margin-top:10px;
    }
    @media (max-width:1366px) {
      padding:18px;
      margin-top:12px;
    }
    .support-title{
      font-weight: bold;
      text-transform: uppercase;
      font-size: 24px;
      @media (max-width:576px) {
        font-size: 16px;
      }
      @media (max-width:1366px) {
        font-size: 18px;
      }
    }
    .tabs{
      .nav-tabs{
        border-bottom: 1px solid #EBEBEB;
        @media (max-width:576px) {
          display: flex;
          white-space: nowrap;
          flex-wrap: nowrap;
          overflow-x: auto;
          overflow-y: hidden;
        }
        .nav-item{
          margin-right: 30px;
          @media (max-width:576px) {
            margin-right: 16px;
          }
          @media (max-width:1366px) {
            margin-right: 18px;
          }
          &:last-child{
            margin-right: 0;
          }
          .nav-link{
            font-weight: 500;
            border:none;
            color: #65676B;
            background: transparent;
            font-size: 16px;
            padding: 0.5rem 0;
            @media (max-width:576px) {
              font-size: 14px;
              padding: 0.35rem 0;
            }
            @media (max-width:1366px) {
              font-size: 15px;
            }
            &.active{
              color: #004390;
              font-weight: bold;
              position: relative;
              &::before{
                content: '';
                background: #004390;
                position: absolute;
                bottom:0;
                left:50%;
                transform: translateX(-50%);
                height: 4px;
                width:70px;
                @media (max-width:576px) {
                  height: 3px;
                  width:50px;
                }
                @media (max-width:1366px) {
                  height: 3px;
                  width: 60px;
                }
              }
            }
          }
        }
      }
    }
    .card-text {
      .div-img {
        .img-register {
          width: 800px;
          height: 416px;
          margin: 12px 0;
        }
        .img-register-1 {
          width: 785px;
          height: 455px;
          margin: 12px 0;
        }
        .img-register-gg {
          width: 516px;
          height: 455px;
          margin: 12px 0;
        }
      }
    }
  }
  .min-height{
    min-height: 60vh;
    @media (max-width:576px) {
      min-height: 50vh;
    }
  }
}
</style>
