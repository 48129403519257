<template>
  <div class="pb-5 ml-huong-dan-cong-diem">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <div class="mb-3"><span>Nội quy</span> / <span>Cơ chế cộng điểm</span></div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <b-tabs v-model="tabActive" nav-class="border-0 align-items-center">
            <b-tab title="Cơ chế cộng điểm">
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <div class="container container-mobile container-responsive">
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <div class="huong-dan-cong-diem">
            <table class="table table-bordered table-point" v-if="tabActive === 0">
              <thead>
                <tr class="text-center">
                  <th width="50">STT</th>
                  <th class="text-left text-lg-center">Hoạt động</th>
                  <th class="text-left text-lg-center" width="100">Số điểm</th>
                  <th v-if="checkBonus" class="text-left text-lg-center" width="100" style="color: red">Bonus</th>
                  <th class="text-left text-lg-center" width="200">Thời gian<span> cộng điểm</span></th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center" v-for="(it, ind) in datas" :key="ind">
                  <td>{{ind + 1}}</td>
                  <td class="text-left"><label class="mr-1">{{ind + 1}}.</label>{{it.name}}</td>
                  <td class="text-left text-lg-center">
                    <template v-if="it.type">
                      {{ it.type === 'POINT_ADD' ? '+' : '-' }}{{it.point - it.bonus}}
                    </template>
                    <template v-else>
                      {{ it.point }}
                    </template>
                    <span> điểm</span>
                  </td>
                  <td v-if="it.bonus !== 0 && checkBonus" class="text-left text-lg-center" style="color: red; font-weight: 500">
                    <template>
                      +{{it.bonus}}
                    </template>
                    <span> điểm</span>
                  </td>
                  <td v-else-if="it.bonus === 0 && checkBonus" class="text-left text-lg-center">
                  </td>
                  <td class="text-left text-lg-center">{{it.action_time}}</td>
                </tr>
              </tbody>
            </table>
<!--            <template v-if="tabActive === 1 && authenticated">-->
<!--              <table class="table table-bordered table-history">-->
<!--                <thead>-->
<!--                  <tr class="text-center">-->
<!--                    <th width="100">Điểm</th>-->
<!--                    <th>Hoạt động</th>-->
<!--                    <th width="200">Thời gian</th>-->
<!--                  </tr>-->
<!--                </thead>-->
<!--                <tbody>-->
<!--                  <tr class="text-center" v-for="(it, ind) in historyScore" :key="ind">-->
<!--                    <td>{{it.point}}</td>-->
<!--                    <td class="text-left">{{it.mission}}</td>-->
<!--                    <td>{{it.time}}</td>-->
<!--                  </tr>-->
<!--                </tbody>-->
<!--              </table>-->
<!--              <Pagination class="mt-3" :limit="query.limit" :total="query.total" :value="query.page" @change="getListHistory" />-->
<!--            </template>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Resource from '~/common/api/resource'
// import Pagination from '~/components/mlearn/pagination/index.vue'
const scoreApi = new Resource('score')
// const historyScoreApi = new Resource('score/historyScore')
export default {
  components: {
    // Pagination
  },
  layout: 'mlearn',
  data () {
    return {
      tabActive: 0,
      datas: [],
      dataBonus: [],
      checkBonus: false,
      historyScore: [],
      query: {
        page: 1,
        total: 0,
        limit: 10
      }
    }
  },
  computed: {
    ...mapState([
      'authenticated'
    ])
  },
  created () {
    this.getListScore()
    // this.getListHistory()
  },
  methods: {
    getListScore () {
      scoreApi.list().then((response) => {
        this.datas = response.data
        this.dataBonus = this.datas.map(item => item.bonus)
        console.log('dataBonus', this.dataBonus)
        this.checkBonus = this.dataBonus.some(item => item !== 0)
        console.log(this.checkBonus)
      }).catch(() => {
      })
    }
    // getListHistory (page = 1) {
    //   if (this.authenticated) {
    //     this.query.page = page
    //     historyScoreApi.list(this.query).then((response) => {
    //       this.historyScore = response.data
    //       this.query.total = response.total
    //     })
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.ml-huong-dan-cong-diem{
  @media (max-width:576px) {
    background: var(--white);
  }
  .container-responsive {
    @media (max-width:576px) {
      padding: 0 16px;
    }
  }
  .huong-dan-cong-diem{
    background: var(--white);
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
    padding:3px 24px 24px 24px;
    border-width: 0px 1px 1px 1px;
    border-color: #004390;
    border-style: solid;
    @media (max-width:576px) {
      padding:16px 0 0;
      box-shadow: none;
    }
    @media (max-width:1366px) {
      padding:16px 0 0;
    }
    .table{
      font-size: 16px;
      @media (max-width:576px) {
        font-size: 14px;
      }
      tbody{
        tr{
          td{
            &:nth-child(3){
              font-weight: 500;
            }
          }
        }
      }
      &.table-point{
        @media (max-width:576px) {
          border:0;
        }
        thead{
          background: #F0F0F0;
        }
        thead, tbody{
          tr{
            th {
              border-bottom: 0;
            }
            th, td{
              vertical-align: middle;
              @media (max-width:576px) {
                border:0;
                border-top: 1px solid #EBEBEB;
              }
              &:nth-child(1), span{
                @media (max-width:576px) {
                  display: none;
                };
              }
              label{
                display: none;
                @media (max-width:576px) {
                  display: inline;
                };
              }
              &:nth-child(2){}
              &:nth-child(3){
                @media (max-width:576px) {
                  width:80px;
                }
              }
              &:nth-child(4){
                @media (max-width:576px) {
                  width:110px;
                }
              }
            }
          }
        }
      }
      &.table-history{
        @media (max-width:576px) {
          border:0;
        }
        thead{
          background: #F0F0F0;
        }
        thead, tbody{
          tr{
            th {
              border-bottom: 0;
            }
            th, td{
              vertical-align: middle;
              @media (max-width:576px) {
                border:0;
                border-top: 1px solid #EBEBEB;
              }
              &:nth-child(1){
                @media (max-width:576px) {
                  width:60px;
                }
              }
              &:nth-child(2){}
              &:nth-child(3){
                @media (max-width:576px) {
                  width:105px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
  .ml-huong-dan-cong-diem{
    .tabs{
      background: var(--white);
      border-radius: 4px 4px 0 0;
      box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
      padding:20px 24px 12px 24px;
      border-width: 1px 1px 0 1px;
      border-color: #004390;
      border-style: solid;
      @media (max-width:576px) {
        padding:0;
        box-shadow: none;
      }
      @media (max-width:1366px) {
        padding:16px 16px 10px 16px;
      }
      .nav-tabs{
        .nav-item{
          border-right: 1px solid #6B6D70;
          &:first-child{
            .nav-link{
              padding-left: 0;
            }
          }
          &:last-child{
            border-right: 0;
            .nav-link{
              padding-right: 0;
              @media (max-width:576px) {
                padding-left: 10px;
              }
            }
          }
          .nav-link{
            padding-top: 0;
            padding-bottom: 0;
            background: initial;
            font-weight: 500;
            color: #6B6D70;
            border: 0;
            font-size: 16px;
            @media (max-width:576px) {
              font-size: 14px;
              padding-right: 10px;
            }
            @media (max-width:1366px) {
              font-size: 15px;
            }
            &.active{
              font-weight: bold;
              font-size: 20px;
              color: initial;
              @media (max-width:576px) {
                font-size: 16px;
              }
              @media (max-width:1366px) {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
</style>
